import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { url } from "inspector";
import { ReactComponent as AboutSide } from "../images/aboutside.svg";
import test from "../images/aboutside.svg";
import "./about.css";
export const About = () => {
  const cutoutphoto = require("../images/cutoutphoto.png");
  const spraypaint = require("../images/spraypaint.png");
  return (
    <>
      <Grid className="testing">
        <Grid container alignItems="flex-start">
          <Grid
            item
            xs={10}
            md={5}
            style={{ paddingTop: "20px", paddingLeft: "20px" }}
          >
            <img
              src={String(spraypaint)}
              style={{
                objectFit: "contain",
                width: "90%",
                height: "90%",
              }}
            />
          </Grid>
          <Grid
            item
            xs={10}
            md={5}
            alignItems="left"
            style={{
              width: "100%",
              paddingTop: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
            }}
          >
            <Typography
              variant="h2"
              gutterBottom
              component="div"
              style={{ marginBottom: 5, textAlign: "center" }}
            >
              About Me
            </Typography>
            <Typography
              variant="h4"
              gutterBottom
              component="div"
              style={{ textAlign: "center" }}
            >
              Software Engineer and Artist
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              component="div"
              style={{ textAlign: "center" }}
            >
              I'm a Software Engineer born and raised in Southwest Iowa. I grew
              up with a love for computers, at first I loved to take them apart,
              then learned to fix them, and finally went to college at UCI as a
              Software engineer.
            </Typography>
          </Grid>
          <Grid item xs={2} style={{}}></Grid>
        </Grid>
      </Grid>
    </>
  );
};
