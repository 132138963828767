import Typography from "@mui/material/Typography";
import Grid, { GridProps } from "@mui/material/Grid";
import { alpha, styled } from "@mui/material/styles";
import { useState } from "react";
import { ReactComponent as Brain } from "../images/brain1.svg";

interface StyledGridProps extends GridProps {
  backgroundvariable?: any;
}

export function HomePage() {
  const programmingSideContent = {
    title: "Analytical Side",
    body: "Click on this side to explore my programming experience and learn more about my skills as a programmer",
  };
  const creativeSideContent = {
    title: "Hobbies and Art",
    body: "Click on this side to explore my Hobbies and learn more about me as a person",
  };
  const backgroundImage = require("../images/brain.svg");
  const [activeSide, setActiveSide] = useState("");
  const HomeGridPanelLeft = styled(Grid)<StyledGridProps>(
    ({ backgroundvariable, theme }) => ({
      width: "100%",
      height: "100%",
      minHeight: "100vh",
      backgroundColor:
        backgroundvariable === "right" ? "rgba(0.9,0,0,0.5)" : "",
      "&:hover": {
        background:
          "linear-gradient(to right, rgba(0.8,0,0,0.1), rgba(0.8,0,0,0.4), rgba(0.9,0,0,0.5))",
      },
    })
  );

  const HomeGridPanelRight = styled(Grid)<StyledGridProps>(
    ({ backgroundvariable, theme }) => ({
      width: "100%",
      height: "100%",
      minHeight: "100vh",
      backgroundColor: backgroundvariable === "left" ? "rgba(0.9,0,0,0.5)" : "",
      "&:hover": {
        background:
          "linear-gradient(to left, rgba(0.8,0,0,0.1), rgba(0.8,0,0,0.4), rgba(0.9,0,0,0.5))",
      },
    })
  );

  const renderSideText = (contentObject: any) => (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      style={{ height: "75%", minHeight: "75vh" }}
    >
      <Grid item xs={6}>
        <Typography align="center" variant="h4" color="white">
          {contentObject.title}
        </Typography>
        <Typography align="center" variant="h6" color="white">
          {contentObject.body}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid style={{ position: "relative" }}>
        <Grid
          container
          style={{
            zIndex: "9",
            position: "absolute",
            height: "100%",
            minHeight: "100vh",
          }}
        >
          <HomeGridPanelLeft
            backgroundvariable={activeSide}
            onMouseLeave={(e) => setActiveSide("")}
            onMouseEnter={(e) => setActiveSide("left")}
            onClick={(e) => {
              console.log("programming");
            }}
            item
            xs={6}
          >
            {activeSide === "left" && (
              <>{renderSideText(programmingSideContent)}</>
            )}
          </HomeGridPanelLeft>
          <HomeGridPanelRight
            backgroundvariable={activeSide}
            onMouseLeave={(e) => setActiveSide("")}
            onMouseEnter={(e) => setActiveSide("right")}
            onClick={(e) => {
              console.log("art");
            }}
            item
            xs={6}
          >
            {activeSide === "right" && (
              <>{renderSideText(creativeSideContent)}</>
            )}
          </HomeGridPanelRight>
        </Grid>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ position: "absolute" }}
        >
          <Grid
            item
            style={{
              width: "100%",
              height: "100%",
              textAlign: "center",
              dominantBaseline: "middle",
              textAnchor: "middle",
            }}
          >
            <Brain
              style={{
                display: "block",
                maxWidth: "100vh",
                maxHeight: "100vh",
                margin: "auto",
              }}
            ></Brain>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
