import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { HomePage } from "./pages/home";
import { Programming } from "./pages/programming";
import { About } from "./pages/about";
import { Art } from "./pages/art";
import { NavBar } from "./Components/NavBar";
import { SplashScreen } from "./Components/SplashScreen";
function App() {
  return (
    <BrowserRouter>
      <SplashScreen />
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<About />} />
        <Route path="/programming" element={<Programming />} />
        <Route path="/art" element={<Art />} />
        <Route element={Error} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
