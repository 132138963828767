import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import octogon from "../images/octogon.png";
import { useState } from "react";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
} from "@devexpress/dx-react-chart-material-ui";

export const Programming = () => {
  const [counter, setCounter] = useState(0);
  const frontEndChart = [
    { argument: "PHP", value: 2.5 },
    { argument: "Angular", value: 2 },
    { argument: "CSS", value: 3.5 },
    { argument: "Node", value: 4 },
    { argument: "React", value: 4 },
    { argument: "JS/TypeScript", value: 4 },
  ];

  const backEndChart = [
    { argument: "Java", value: 3 },
    { argument: "Python", value: 3 },
    { argument: "GCP", value: 4 },
    { argument: "Docker", value: 3 },
    { argument: "MySQL", value: 4 },
  ];

  const technologiesChart = [
    { argument: "Figma", value: 2 },
    { argument: "Looker", value: 3.5 },
    { argument: "Cypress", value: 4 },
    { argument: "PhotoShop", value: 3 },
    { argument: "Illustrator", value: 3 },
    { argument: "ProCreate", value: 4 },
  ];
  function renderSwitch(param: any) {
    switch (param) {
      case "1.0":
        return "Beginner";
      case "2.0":
        return "Intermediate";
      case "3.0":
        return "Advanced";
      case "4.0":
        return "Expert";
      default:
        return "";
    }
  }

  const RenderLevelLabel =
    () =>
    ({ text, style, ...restProps }: any) =>
      (
        <ValueAxis.Label
          text={renderSwitch(text)}
          style={{
            fill: "black",
            fontSize: "15px",
            ...style,
          }}
          {...restProps}
        />
      );

  const renderXAxis =
    () =>
    ({ text, style, ...restProps }: any) =>
      (
        <ValueAxis.Label
          text={`${text}`}
          style={{
            fill: "black",
            fontSize: "15px",
            ...style,
          }}
          {...restProps}
        />
      );

  const ShowLine =
    () =>
    ({ ...restProps }: any) => {
      if (counter % 2 === 0) {
        return <ValueAxis.Line {...restProps} />;
      } else {
        return <ValueAxis.Line {...restProps} />;
      }
    };

  const PriceLabel = renderXAxis();

  const LevelLabel = RenderLevelLabel();

  const SkillsCard = ({ title, data }: any) => (
    <Grid
      container
      style={{
        padding: "2% 2% 0% 2%",
        margin: "0px 0px 0px 0px",
      }}
    >
      <Grid item xs={12}>
        <Card raised={true}>
          <Grid
            container
            style={{
              padding: "2% 2% 2% 2%",
              margin: "0px 0px 0px 0px",
            }}
          >
            <Grid item xs={12} md={6}>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={2}>
                  {" "}
                  <img
                    src={octogon}
                    style={{
                      width: "100%",
                      height: "100%",
                      minHeight: "50px",
                      minWidth: "50px",
                      maxHeight: "125px",
                      maxWidth: "125px",
                    }}
                    alt="octo"
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography
                    variant="h3"
                    gutterBottom
                    component="div"
                    style={{ marginBottom: "0px" }}
                  >
                    {title}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-start" alignItems="center">
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    Can put a bunch of stuff in here about all of your different
                    jobs and stuff like that and why you are a front end dev and
                    blah
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    Can put a bunch of stuff in here about all of your different
                    jobs and stuff like that and why you are a front end dev and
                    blah
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    Can put a bunch of stuff in here about all of your different
                    jobs and stuff like that and why you are a front end dev and
                    blah
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    Can put a bunch of stuff in here about all of your different
                    jobs and stuff like that and why you are a front end dev and
                    blah
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                paddingRight: "25px",
                minWidth: "450px",
                overflow: "scroll",
              }}
            >
              <Chart data={data} rotated={true}>
                <ArgumentAxis
                  showGrid={false}
                  showLine={false}
                  labelComponent={PriceLabel}
                ></ArgumentAxis>
                <ValueAxis
                  labelComponent={LevelLabel}
                  gridComponent={ShowLine()}
                />
                <BarSeries
                  barWidth={0.7}
                  valueField="value"
                  argumentField="argument"
                  color="black"
                />
              </Chart>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );

  return (
    <Grid style={{ maxWidth: "100vw" }}>
      <SkillsCard title="FrontEnd" data={frontEndChart} />
      <SkillsCard title="Backend" data={backEndChart} />
      <SkillsCard title="Technologies" data={technologiesChart} />
    </Grid>
  );
};
