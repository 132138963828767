import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
export const NavBar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        style={{ backgroundColor: "black", boxShadow: "none" }}
      >
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <div
              style={{
                overflow: "hidden",
                width: "50px",
                height: "50px",
                position: "relative",
              }}
            >
              <img
                src={Logo}
                style={{
                  width: "120px",
                  height: "120px",
                  position: "absolute",
                  bottom: "-50px",
                  right: "-40px",
                }}
                alt="Logo"
              />
            </div>
          </Typography>
          <Button component={Link} to="/" color="inherit">
            Home
          </Button>
          <Button component={Link} to="/about" color="inherit">
            About
          </Button>
          <Button component={Link} to="/art" color="inherit">
            Art
          </Button>
          <Button component={Link} to="/programming" color="inherit">
            Programming
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
