import { ReactComponent as SplashLogo } from "../images/AnimationLogo.svg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import { useLocation } from "react-router-dom";

import "./splashcss.css";
import { useState, useEffect } from "react";
export const SplashScreen = () => {
  const [checked, setChecked] = useState(true);
  const location = useLocation();

  useEffect(() => {
    const timer = setTimeout(() => {
      setChecked(false);
    }, 4000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {location.pathname === "/" && (
        <Fade in={checked} timeout={3000} appear={false}>
          <Grid
            container
            onClick={() => {
              setChecked(false);
            }}
            justifyContent="center"
            xs={12}
            style={{
              backgroundColor: "black",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              display: "flex",
              zIndex: 99,
              position: "absolute",
            }}
          >
            <Grid item xs={8} justifyContent="center">
              <SplashLogo style={{ width: "100%", height: "100%" }} />
            </Grid>
          </Grid>
        </Fade>
      )}
    </>
  );
};

export {};
