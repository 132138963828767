import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Grid from "@mui/material/Grid";

import albumCover from "../images/art/albumcover.JPG";
import avocado from "../images/art/avocado.JPG";
import lufi from "../images/art/lufi.JPG";
import monkeyclearhand from "../images/art/monkeyclearhand.jpg";
import pel from "../images/art/pel.jpg";
import skelgun from "../images/art/skelgun.jpg";

import affy2 from "../images/art/affy2.JPG";
import affyone from "../images/art/affyone.JPG";
import backgroundimg from "../images/art/backgroundimg.JPG";
import bee from "../images/art/bee.JPG";
import beer from "../images/art/beer.JPG";
import candle from "../images/art/candle.JPG";
import cartoonmemo from "../images/art/cartoonmemo.PNG";
import clinteastwood from "../images/art/clinteastwood.jpeg";
import deer from "../images/art/deer.JPG";
import deerclose from "../images/art/deerclose.JPG";
import dog from "../images/art/dog.JPG";
import eagle from "../images/art/eagle.JPG";
import eyepurple from "../images/art/eyepurple.JPG";
import eyesketch from "../images/art/eyesketch.jpg";
import flag from "../images/art/flag.JPG";
import grandpa from "../images/art/grandpa.JPG";
import joker from "../images/art/joker.JPG";
import jwickmanlogo from "../images/art/jwickmanlogo.jpg";
import lufiuncolored from "../images/art/lufiuncolored.JPG";
import maltattoo from "../images/art/maltattoo.JPG";
import octoclose from "../images/art/octoclose.JPG";
import plantwall from "../images/art/plantwall.JPG";
import scribbleeye from "../images/art/scribbleeye.JPG";
import skull from "../images/art/skull.JPG";
import tree from "../images/art/tree.JPG";
import voicememoline from "../images/art/voicememoline.PNG";
import wingologo from "../images/art/wingologo.jpg";
import wingsketch from "../images/art/wingsketch.jpg";
import wolf from "../images/art/wolf.JPG";
const itemData = [
  {
    img: albumCover,
    title: "Breakfast",
  },
  {
    img: avocado,
    title: "Burger",
  },
  {
    img: lufi,
    title: "Camera",
  },
  {
    img: monkeyclearhand,
    title: "Coffee",
  },
  {
    img: pel,
    title: "Hats",
  },
  {
    img: skelgun,
    title: "Honey",
  },
  {
    img: affyone,
    title: "Breakfast",
  },
  {
    img: backgroundimg,
    title: "Breakfast",
  },
  {
    img: bee,
    title: "Breakfast",
  },
  {
    img: beer,
    title: "Breakfast",
  },
  {
    img: candle,
    title: "Breakfast",
  },
  {
    img: cartoonmemo,
    title: "Breakfast",
  },
  {
    img: clinteastwood,
    title: "Breakfast",
  },
  {
    img: deer,
    title: "Breakfast",
  },
  {
    img: deerclose,
    title: "Breakfast",
  },
  {
    img: affy2,
    title: "Breakfast",
  },
  {
    img: dog,
    title: "Breakfast",
  },
  {
    img: eagle,
    title: "Breakfast",
  },
  {
    img: eyepurple,
    title: "Breakfast",
  },
  {
    img: eyesketch,
    title: "Breakfast",
  },
  {
    img: flag,
    title: "Breakfast",
  },
  {
    img: grandpa,
    title: "Breakfast",
  },
  {
    img: joker,
    title: "Breakfast",
  },
  {
    img: jwickmanlogo,
    title: "Breakfast",
  },
  {
    img: lufiuncolored,
    title: "Breakfast",
  },
  {
    img: maltattoo,
    title: "Breakfast",
  },
  {
    img: octoclose,
    title: "Breakfast",
  },
  {
    img: plantwall,
    title: "Breakfast",
  },
  {
    img: scribbleeye,
    title: "Breakfast",
  },
  {
    img: skull,
    title: "Breakfast",
  },
  {
    img: tree,
    title: "Breakfast",
  },
  {
    img: wingologo,
    title: "Breakfast",
  },
  {
    img: wingsketch,
    title: "Breakfast",
  },
  {
    img: wolf,
    title: "Breakfast",
  },
  {
    img: voicememoline,
    title: "Breakfast",
  },
];

export const Art = () => {
  return (
    <Grid
      xs={12}
      style={{ backgroundColor: "black", width: "100%", height: "100vh" }}
    >
      <ImageList
        cols={3}
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: 0,
          marginBottom: 0,
          backgroundColor: "black",
        }}
      >
        {itemData.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
              srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
              alt={item.title}
              loading="lazy"
            />
          </ImageListItem>
        ))}
      </ImageList>
    </Grid>
  );
};
